import * as React from "react";
import styled from "styled-components";
import QuestionLabel from "./questionLabel";
import { INPUT_LABEL, Languages, SUBMIT_TEXT } from "../resources";
import QuestionTextArea from "./questionTextArea";
import SubmitButton from "./submitButton";

const Form = styled.form`
  position: relative;
  transition: ${props => props.theme.transition};
  width: 100%;
`;

interface QuestionFormProps {
  lang: Languages;
  query: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => any;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
}

export default ({
  lang,
  query,
  onChange,
  onFocus,
  onKeyDown,
  onSubmit,
  placeholder
}: QuestionFormProps) => {
  const [focus, setFocus] = React.useState<boolean>(false);
  return (
    <Form onSubmit={onSubmit}>
      <label htmlFor="ask-question">
        <QuestionTextArea
          value={query}
          onChange={onChange}
          focus={focus}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          setFocus={setFocus}
        />
        <QuestionLabel>{placeholder || INPUT_LABEL(lang)}</QuestionLabel>
        <SubmitButton type="submit">{SUBMIT_TEXT(lang)}</SubmitButton>
      </label>
    </Form>
  );
};
