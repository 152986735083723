import * as React from "react";
import * as queryString from "query-string";
import AddressView from "./AddressView";
import PrimaryAnswer from "../components/primaryAnswer";
import AnswerDetails from "../components/answerDetails";
import AnswerOptions from "../components/answerOptions";
import { useParams } from "react-router-dom";
import getAnswerByName from "../api/getAnswerByName";
import languageToCode from "../utils/languageToCode";
import { Languages, FOUND_ANSWER_TEXT } from "../resources";
import Prompt from "../components/prompt";
import bestUtterance from "../utils/bestUtterance";
import { IntentDetailResponse } from "../api/intentDetailResponse";
import Loader from "../components/loader";
import FeedbackPrompt from "../components/feedbackPrompt";
import codeToLanguage from "../utils/codeToLanguage";
import getCsrfToken from "../api/getCsrfToken";
import StickyContainer from "../components/stickyContainer";

export default ({
  answer,
  history,
  lang,
  query,
  setAnswer,
  setHistory,
  setLang,
  setRedirect,
  useQuery
}: {
  answer: IntentDetailResponse;
  history: string[];
  lang: Languages;
  query: string;
  setAnswer: React.Dispatch<React.SetStateAction<IntentDetailResponse>>;
  setHistory: React.Dispatch<React.SetStateAction<string[]>>;
  setLang: (lang: Languages) => void;
  setRedirect: React.Dispatch<React.SetStateAction<string>>;
  useQuery: boolean;
}) => {
  const { intent } = useParams<{ intent: string }>();
  const [csrf, setCsrf] = React.useState<string>("");

  React.useEffect(() => {
    const language = queryString.parse(window.location.search).lang;
    if (language && typeof language === "string") {
      if (
        language !== "en" &&
        language !== "vi" &&
        language !== "es" &&
        language !== "zh"
      )
        return;
      setLang(codeToLanguage(language));
    }
    getCsrfToken().then(res => {
      setCsrf(res.token);
    });
  }, []);

  React.useEffect(() => {
    let isSuggestion =
      queryString.parse(window.location.search).suggestion === "true";

    getAnswerByName(
      intent,
      languageToCode(lang),
      query,
      useQuery,
      isSuggestion
    ).then(res => {
      setAnswer(res);
    });
  }, [intent, lang]);

  // loading state
  if (answer === null) {
    return (
      <>
        <Prompt
          dangerouslySetInnerHTML={{
            __html: FOUND_ANSWER_TEXT(lang, "...")
          }}
        />
        <Loader />
      </>
    );
  }

  // if it's an address intent, shortcut out and render the AddressView
  // (enough complex logic happens there to make it its own view)
  if (answer.is_address_intent) {
    return (
      <AddressView
        answer={answer}
        csrf={csrf}
        history={history}
        lang={lang}
        setHistory={setHistory}
        setRedirect={setRedirect}
      />
    );
  }

  return (
    <>
      <Prompt
        dangerouslySetInnerHTML={{
          __html: FOUND_ANSWER_TEXT(
            lang,
            query && useQuery ? `"${query}"` : bestUtterance(answer.utterances)
          )
        }}
      />
      {answer.primary_answer && (
        <PrimaryAnswer
          dangerouslySetInnerHTML={{ __html: answer.primary_answer }}
        />
      )}
      {answer.answer_details && (
        <AnswerDetails
          dangerouslySetInnerHTML={{ __html: answer.answer_details }}
        />
      )}
      <StickyContainer>
        <AnswerOptions history={history} lang={lang} setHistory={setHistory} />
      </StickyContainer>
      <FeedbackPrompt
        analyticsId={answer.analytics_id}
        csrf={csrf}
        lang={lang}
      />
    </>
  );
};
