import { AddressResponseData } from "./api/addressLookup";
import languageToCode from "./utils/languageToCode";

export enum Languages {
  EN = "English",
  ES = "Español",
  VI = "Tiếng Việt",
  ZH = "中國人"
}

export enum Views {
  DEFAULT = "Default",
  FOCUSED = "Focused",
  ANSWER = "Answer",
  ADDRESS = "Address",
  ADDRESS_ANSWER = "Address Answer",
  INTENT_LIST = "Intent List"
}

export function PROMPTS({
  view,
  lang,
  inputValue,
  prompt
}: {
  view: Views;
  lang: Languages;
  inputValue?: string;
  prompt?: string;
}): string {
  if (prompt) return prompt;
  switch (view) {
    case Views.DEFAULT:
    case Views.FOCUSED:
      const welcomeMessage = JSON.parse(
        document.getElementById("welcome-message").textContent
      );
      const code = languageToCode(lang);
      return welcomeMessage[code];
    case Views.ADDRESS:
      return ADDRESS_TEXT(lang);
    case Views.ADDRESS_ANSWER:
    case Views.ANSWER:
      return FOUND_ANSWER_TEXT(lang, inputValue);
  }
  return "";
}

export enum Results {
  INSANJOSE = "In San Jose",
  NOTINSANJOSE = "Not In San Jose"
}

export function BACK_TEXT(lang: Languages): string {
  switch (lang) {
    case Languages.EN:
      return "Back";
    case Languages.ES:
      return "Atrás";
    case Languages.VI:
      return "Trở lại";
    case Languages.ZH:
      return "後退";
  }
}

export function WELCOME_MESSAGE(lang: Languages): string {
  switch (lang) {
    case Languages.EN:
      return "How may I help you?";
    case Languages.ES:
      return "¿Cómo podemos ayudarle?";
    case Languages.VI:
      return "Tôi có thể giúp gì cho quý vị?";
    case Languages.ZH:
      return "我怎樣能幫到你？";
  }
  return "";
}

export function INPUT_LABEL(lang: Languages): string {
  switch (lang) {
    case Languages.EN:
      return "Ask a question here...";
    case Languages.ES:
      return "Haz una pregunta aquí ...";
    case Languages.VI:
      return "Đặt câu hỏi tại đây ...";
    case Languages.ZH:
      return "在這裡問一個問題...";
  }
}

export function SUBMIT_TEXT(lang: Languages): string {
  switch (lang) {
    case Languages.EN:
      return "Submit";
    case Languages.ES:
      return "Enviar";
    case Languages.VI:
      return "Gửi đi";
    case Languages.ZH:
      return "提交";
  }
}

export function SUGGESTION_LABEL_TEXT(lang: Languages): string {
  switch (lang) {
    case Languages.EN:
      return "Suggestions";
    case Languages.ES:
      return "Sugerencias";
    case Languages.VI:
      return "Gợi ý";
    case Languages.ZH:
      return "建議";
  }
}

export function TOP_QUESTIONS_LABEL_TEXT(lang: Languages): string {
  switch (lang) {
    case Languages.EN:
      return "Top Questions";
    case Languages.ES:
      return "Preguntas principales";
    case Languages.VI:
      return "Câu hỏi hàng đầu";
    case Languages.ZH:
      return "熱門問題";
  }
}

export enum SUGGESTION_TYPE {
  INTENT_WRAPPER,
  INTENT
}

interface TopQuestion {
  text: string;
  name: string;
}

export function isTopQuestion(o: any): o is TopQuestion {
  return o && o.hasOwnProperty("name") && !o.hasOwnProperty("type");
}

export function ANSWER_TEXT(lang: Languages): string {
  switch (lang) {
    case Languages.EN:
      return "The time limit for meters in San Jose varies from 12 minutes up to 4 hours, with the majority being set for one hour. The maximum time limit for a specific meter is indicated on the meter and by posted street signs.<br /><br />The rate for most parking meters ranges betwen $1.00 and $2.00 per hour, and will be indicated on the meter. The event rate for metered parking in the SAP Arena area and in the vicinity of the Convention Center varies between $0 and $25 depending upon the event; and will be indicated on the pay-stations.<br /><br />To report a possible broken meter, please call (408) 534-2922";
    case Languages.ES:
      return "El límite de tiempo para los medidores en San José varía de 12 minutos a 4 horas, y la mayoría se establece en una hora. El límite de tiempo máximo para un medidor específico se indica en el medidor y en los letreros de las calles. <br /> <br /> La tarifa para la mayoría de los parquímetros varía entre $ 1.00 y $ 2.00 por hora, y se indicará en el medidor. La tarifa del evento para estacionamiento medido en el área de SAP Arena y en las cercanías del Centro de Convenciones varía entre $ 0 y $ 25 dependiendo del evento; y se indicará en las estaciones de pago. <br /> <br /> Para reportar un posible medidor roto, llame al (408) 534-2922";
    case Languages.VI:
      return "Giới hạn thời gian cho mét ở San Jose thay đổi từ 12 phút đến 4 giờ, với phần lớn được đặt trong một giờ. Giới hạn thời gian tối đa cho một đồng hồ cụ thể được chỉ định trên đồng hồ và bằng các biển báo đường phố được đăng. Tỷ lệ sự kiện cho bãi đậu xe có đồng hồ đo trong khu vực Đấu trường SAP và khu vực lân cận Trung tâm Hội nghị dao động trong khoảng từ 0 đến 25 đô la tùy thuộc vào sự kiện; và sẽ được chỉ định trên các trạm trả tiền. <br /> <br /> Để báo cáo đồng hồ bị hỏng có thể xảy ra, vui lòng gọi (408) 534-2922";
    case Languages.ZH:
      return "聖何塞的儀表時間限制從 12 分鐘到 4 小時不等，大多數設置為一小時。 特定計時器的最長時間限制在計時器上和張貼的路牌上標明。<br /><br />大多數停車計時器的費率在每小時 1.00 美元到 2.00 美元之間，並且會在計時器上顯示。 SAP Arena 區域和會議中心附近的收費停車費根據活動的不同在 0 美元到 25 美元之間變化； 並將顯示在收費站上。<br /><br />如需報告可能損壞的電錶，請致電 (408) 534-2922";
  }
}

export function ANSWER_TEXT_LABEL(lang: Languages): string {
  switch (lang) {
    case Languages.EN:
      return "Here's What I Found";
    case Languages.ES:
      return "Esto es lo que encontré";
    case Languages.VI:
      return "Đây là những gì tôi tìm thấy";
    case Languages.ZH:
      return "這是我發現的";
  }
}

export function OPTION_LABEL_TEXT(lang: Languages): string {
  switch (lang) {
    case Languages.EN:
      return "Options";
    case Languages.ES:
      return "Opciones";
    case Languages.VI:
      return "Tùy chọn";
    case Languages.ZH:
      return "選項";
  }
}

export function HELP(lang: Languages): string {
  switch (lang) {
    case Languages.EN:
      return "Help";
    case Languages.ES:
      return "Ayuda";
    case Languages.VI:
      return "Trợ giúp";
    case Languages.ZH:
      return "幫助";
  }
}

export function OPTION_TEXT(lang: Languages): string[] {
  switch (lang) {
    case Languages.EN:
      return ["Help", "Back to Menu"];
    case Languages.ES:
      return ["Ayuda", "Volver al Menú"];
    case Languages.VI:
      return ["Trợ giúp", "Quay lại Menu"];
    case Languages.ZH:
      return ["幫助", "返回菜單"];
  }
}

export function ADDRESS_TEXT(lang: Languages): string {
  switch (lang) {
    case Languages.EN:
      return "What is your address?";
    case Languages.ES:
      return "¿Cual es tu dirección?";
    case Languages.VI:
      return "Địa chỉ của bạn là gì?";
    case Languages.ZH:
      return "你的地址是？";
  }
}

export function ADDRESS_INPUT_LABEL(lang: Languages): string {
  switch (lang) {
    case Languages.EN:
      return "Enter your address here...";
    case Languages.ES:
      return "Ingrese su dirección aquí...";
    case Languages.VI:
      return "Nhập địa chỉ của quý vị tại đây...";
    case Languages.ZH:
      return "在這裡輸入您的地址...";
  }
}

export function ADDRESS_WHY_TEXT(lang: Languages): string {
  switch (lang) {
    case Languages.EN:
      return "Why do you need my address?";
    case Languages.ES:
      return "Why do you need my address?";
    case Languages.VI:
      return "Why do you need my address?";
    case Languages.ZH:
      return "為什麼需要我的地址？";
  }
}

export function ADDRESS_NO_TRACK_TEXT(lang: Languages): string {
  switch (lang) {
    case Languages.EN:
      return "We do not track resident addresses";
    case Languages.ES:
      return "No rastreamos las direcciones de los residentes";
    case Languages.VI:
      return "Chúng tôi không theo dõi địa chỉ thường trú";
    case Languages.ZH:
      return "我們不跟踪居民地址";
  }
}

export function SEARCH_HISTORY_TEXT(lang: Languages): string {
  switch (lang) {
    case Languages.EN:
      return "Search History";
    case Languages.ES:
      return "Buscar Historia";
    case Languages.VI:
      return "Lịch sử tìm kiếm";
    case Languages.ZH:
      return "搜索歷史記錄";
  }
}

export function INFO_TEXT(lang: Languages): string {
  switch (lang) {
    /** TODO: need these links to go somewhere */
    case Languages.EN:
      return "You can schedule a free pick up for large household items. If the item(s) do not come from your property see <a href='#'>Illegal Dumping</a>";
    case Languages.ES:
      return "Puede programar una recolección grande de artículos del hogar grandes. Si el/los artículo(s) no provienen de su hogar, vea <a href='#'>Vertido Ilegal</a>";
    case Languages.VI:
      return "Quý vị có thể đặt lịch thu gom đồ dùng quá khổ miễn phí. Nếu đồ vật cần vất bỏ không phải của gia đình quý vị, xin hãy xem phần <a href='#'>Đổ rác Bất</a> hợp pháp";
    case Languages.ZH:
      return "您可以安排免費取件大型家居用品。 如果物品不是來自您的財產，請參閱<a href='#'>非法傾倒</a>";
  }
}

export function FOUND_ANSWER_TEXT(lang: Languages, text?: string): string {
  let output = "";
  switch (lang) {
    case Languages.EN:
      output += "Here's what I found for ";
      break;
    case Languages.ES:
      output += "Esto es lo que encontré para ";
      break;
    case Languages.VI:
      output += "Đây là những gì tôi tìm được về ";
      break;
    case Languages.ZH:
      output += "這是我找到的";
      break;
  }
  if (text) output += `<br/><b>${text}</b>`;
  return output;
}

export function ADDRESS_ANSWER_TEXT(
  lang: Languages,
  addressResponse: AddressResponseData
): string {
  switch (lang) {
    case Languages.EN:
      return (
        "Your recycling hauler, " +
        addressResponse.WasteCollection.Recycling.Hauler +
        ", will pickup large items for free. Your can call them or fill out their online form to schedule a pickup. You must put your items out 24 hours before the scheduled date."
      );
    case Languages.ES:
      return (
        "Su transportista de reciclaje, " +
        addressResponse.WasteCollection.Recycling.Hauler +
        ", recogerá artículos grandes sin cargo. Puede llamarlos o completar su formulario en línea para programar una recolección.  Debe colocar sus artículos afuera 24 horas antes de la fecha programada."
      );
    case Languages.VI:
      return (
        "Xe vận chuyển rác tái chế của quý vị, " +
        addressResponse.WasteCollection.Recycling.Hauler +
        ", sẽ thu gom đồ vật quá khổ miễn phí. Quý vị có thể gọi cho họ hoặc điền vào mẫu đơn trực tuyến để đặt lịch thu gom.  Quý vị phải để đồ cần vất bỏ ra ngoài 24 tiếng trước ngày đã hẹn."
      );
    case Languages.ZH:
      return (
        "您的回收搬運工，" +
        addressResponse.WasteCollection.Recycling.Hauler +
        ", 將免費上門取件大件物品。 您可以打電話給他們或填寫他們的在線表格來安排取件。 您必須在預定日期前 24 小時將物品取出。"
      );
  }
}

export function ADDRESS_NOT_FOUND_TEXT(lang: Languages): string {
  switch (lang) {
    case Languages.EN:
      return "We couldn't find any information for that address.";
    case Languages.ES:
      return "No pudimos encontrar ninguna información para esa dirección.";
    case Languages.VI:
      return "Chúng tôi không thể tìm thấy bất kỳ thông tin nào cho địa chỉ đó.";
    case Languages.ZH:
      return "我們找不到該地址的任何信息。";
  }
}

export function ACCEPTABLE_ITEMS(lang: Languages): string {
  switch (lang) {
    case Languages.EN:
      return "Acceptable items include:";
    case Languages.ES:
      return "Los artículos aceptables incluyen:";
    case Languages.VI:
      return "Các mặt hàng được chấp nhận bao gồm:";
    case Languages.ZH:
      return "可接受的項目包括：";
  }
}

export function ACCEPTABLE_ITEMS_LIST(lang: Languages): string[] {
  switch (lang) {
    case Languages.EN:
      return [
        "Furniture",
        "Electronic Equipment",
        "Appliances",
        "Yard or House Materials"
      ];
    case Languages.ES:
      return [
        "Muebles",
        "Equipos electrónicos",
        "Aparatos",
        "Materiales de jardín o del hogar"
      ];
    case Languages.VI:
      return [
        "Đồ gỗ",
        "Thiết bị điện tử",
        "Đồ gia dụng",
        "Vật liệu trong nhà hoặc sân vườn"
      ];
    case Languages.ZH:
      return ["家具", "電子設備", "家電", "庭院或房屋材料"];
  }
}

export function ITALICIZED_TEXT(lang: Languages): string {
  switch (lang) {
    /** TODO: make these links functional */
    case Languages.EN:
      return "See full list of <a href='#'>acceptable items</a>";
    case Languages.ES:
      return "Vea la lista completa de <a href='#'>artículos aceptables</a>";
    case Languages.VI:
      return "<a href='#'>Xem toàn bộ danh sách các đồ vật được chấp nhận</a>";
    case Languages.ZH:
      return "查看<a href='#'>可接受的項目</a>的完整列表";
  }
}
