declare global {
  interface Window {
    MAPBOX_TOKEN: string;
  }
}

const mbxClient = require("@mapbox/mapbox-sdk");
const mapboxPublicToken = window.MAPBOX_TOKEN;
const geoCoding = require("@mapbox/mapbox-sdk/services/geocoding");
const geoCodingClient = geoCoding({ accessToken: mapboxPublicToken });

export default async function addressAutocomplete(
  text: string
): Promise<string[]> {
  return geoCodingClient
    .forwardGeocode({
      query: text,
      bbox: [-122.052396, 37.117141, -121.585314, 37.465891],
      types: ["address"]
    })
    .send()
    .then((res: { body: { features: { place_name: string }[] } }) => {
      const match = res.body;
      const features: { place_name: string }[] = match.features || [];
      return features.map(f => f.place_name);
    });
}
