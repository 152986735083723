import styled, { keyframes } from "styled-components";

const loadingGif = require("../loading.gif");

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const Loader = styled.div`
  background-color: rgba(255, 255, 255, 0.75);
  animation: ${fadeIn} 0.25s forwards;
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  background-image: url(${loadingGif});
  background-size: 100px 100px;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100vw;
  height: 100vh;
`;

export default Loader;
