import * as React from "react";
import { Redirect, useParams } from "react-router-dom";
import { Languages } from "../resources";
import { getSuggestions } from "../api/getResources";
import languageToCode from "../utils/languageToCode";
import Prompt from "../components/prompt";
import LineItem from "../components/lineItems";
import { IntentDetailResponse } from "../api/intentDetailResponse";

export default ({
  lang,
  history,
  setAnswer,
  setHistory,
  setUseQuery
}: {
  history: string[];
  lang: Languages;
  setAnswer: React.Dispatch<React.SetStateAction<IntentDetailResponse>>;
  setHistory: React.Dispatch<React.SetStateAction<string[]>>;
  setUseQuery?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const params = useParams<{ index: string }>();
  const index = +params.index;
  const suggestions = getSuggestions(languageToCode(lang));
  if (index < 0 || index > suggestions.length) return <Redirect to="/" />;

  const suggestion = suggestions[index];
  if (!suggestion.children) return <Redirect to="/" />;

  // always clear the answer in this view so that a flash of it
  // isn't seen if going from an answer to here then to a different answer
  React.useEffect(() => setAnswer(null), []);

  return (
    <>
      <Prompt dangerouslySetInnerHTML={{ __html: suggestion.name }} />
      {suggestion.children &&
        suggestion.children.map((child, i) => {
          return (
            <LineItem
              history={history}
              key={i}
              href={`/intent/${child.name}?suggestion=true`}
              dangerouslySetInnerHTML={{ __html: child.utterances }}
              setHistory={setHistory}
              setUseQuery={setUseQuery}
            />
          );
        })}
    </>
  );
};
