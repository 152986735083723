import * as React from "react";
import * as moment from "moment";
import styled from "styled-components";

const containsHTML = (str: string): boolean => {
  return ["p", "div", "ul", "ol", "li", "b", "i", "em", "img"]
    .map(tag => "<" + tag)
    .some(tag => str.indexOf(tag) >= 0);
};

import {
  AddressResponseData,
  AddressResponseError,
  isAddressResponseError,
  AddressIntentType,
  AddressEvent,
  WasteCollection,
  StreetSweeping,
  ParkingPermit
} from "../api/addressLookup";
import AnswerText from "./answerText";
import AddressAnswerBlock from "./addressAnswerBlock";
import { Languages, ADDRESS_NOT_FOUND_TEXT } from "../resources";

const CWS = require("../../images/CalWasteSolutionsLogo.png");
const GT = require("../../images/greenteam.png");
const RS = require("../../images/Republic_Services_logo.png");
const GCS = require("../../images/gardencitysanitation.png");

const calendarIcon = require("../../images/icon--calendar.svg");
const carIcon = require("../../images/icon--car.svg");

interface AddressAnswerProps {
  addressResponse: AddressResponseData | AddressResponseError;
  answer: string;
  history: string[];
  lang: Languages;
  setHistory: React.Dispatch<React.SetStateAction<string[]>>;
  type: AddressIntentType;
}

const AddressAnswer = styled.div`
  line-height: 1.5;
`;

const getHaulerLogo = (hauler: string): string => {
  let logo = null;
  if (hauler === "California Waste Solutions") logo = CWS;
  if (hauler === "GreenTeam of San Jose") logo = GT;
  if (hauler === "Republic Services") logo = RS;
  if (hauler === "Garden City Sanitation") logo = GCS;
  return logo;
};

const isListOfDays = (pickupDay: string): boolean => {
  return (
    ![
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ].includes(pickupDay) && pickupDay.includes(":")
  );
};

const PickupDay = ({
  calendar,
  wasteCollection
}: {
  calendar: AddressEvent[];
  wasteCollection: WasteCollection;
}) => {
  const addressType = wasteCollection.Type;
  const pickupDay = wasteCollection.PickupDay;
  let nextPickup = "";

  for (let i = 0; i < calendar.length; i++) {
    let { Month, Day, Year, Event } = calendar[i];
    let pickup = moment(Month + " " + Day + ", " + Year, "MMMM D, YYYY");
    // < 0 means a date in the future, i.e. next pickup day
    if (moment().diff(pickup) < 0 && Event === "Waste Collection today") {
      nextPickup = Month + " " + Day;
      break;
    }
  }

  return (
    <AddressAnswer>
      <AddressAnswerBlock icon image={calendarIcon}>
        <p>
          {addressType === "COM" ? (
            <>{wasteCollection.Details}</>
          ) : pickupDay === "Contact Hauler" ? (
            <>Contact your hauler to find out your pickup day.</>
          ) : isListOfDays(pickupDay) ? (
            <>
              {pickupDay.split(" ").map(s => {
                const [which, daysAbbrev] = s.split(":");
                const days = daysAbbrev
                  .split(",")
                  .map((a: "Su" | "M" | "Tu" | "W" | "Th" | "F" | "Sa") => {
                    return {
                      Su: "Sunday",
                      M: "Monday",
                      Tu: "Tuesday",
                      W: "Wednesday",
                      Th: "Thursday",
                      F: "Friday",
                      Sa: "Saturday"
                    }[a];
                  });
                return (
                  <>
                    {which}: <b>{days.join(", ")}</b>
                    <br />
                  </>
                );
              })}
            </>
          ) : (
            <>
              Your pickup day is <b>{pickupDay || "None"}</b>.
            </>
          )}{" "}
          {nextPickup && (
            <>
              The next scheduled pickup is <b>{nextPickup}</b>.
            </>
          )}
        </p>
      </AddressAnswerBlock>
    </AddressAnswer>
  );
};

const BulkPickup = ({ hauler, phone }: { hauler: string; phone: string }) => {
  const logo = getHaulerLogo(hauler);
  return (
    <AddressAnswer>
      <AddressAnswerBlock image={logo}>
        <p>
          <b>{hauler}</b>
        </p>
        {phone && (
          <p>
            Call <a href={"tel:" + phone}>{phone}</a>
          </p>
        )}
      </AddressAnswerBlock>
    </AddressAnswer>
  );
};

const Cart = ({ hauler, phone }: { hauler: string; phone: string }) => {
  const logo = getHaulerLogo(hauler);
  return (
    <AddressAnswer>
      <AddressAnswerBlock image={logo}>
        <p>
          <b>{hauler}</b>
        </p>
        {phone && (
          <p>
            Call <a href={"tel:" + phone}>{phone}</a>
          </p>
        )}
      </AddressAnswerBlock>
    </AddressAnswer>
  );
};

const StreetSweeping = ({
  sweepData,
  calendar
}: {
  sweepData: StreetSweeping;
  calendar: AddressEvent[];
}) => {
  const sweepStrings = [
    "Major Road Sweep today",
    "Residential Street Sweep today"
  ];

  const isDowntown = sweepData.Phone.slice(0, 3) === "DOT";

  let nextSweep = "";
  let tMinus: number = null;

  for (let i = 0; i < calendar.length; i++) {
    let { Month, Day, Year, Event } = calendar[i];
    let pickup = moment(Month + " " + Day + ", " + Year, "MMMM D, YYYY");
    // < 0 means a date in the future, i.e. next pickup day
    if (moment().diff(pickup) < 0 && sweepStrings.includes(Event)) {
      nextSweep = Month + " " + Day;
      tMinus = -1 * moment().diff(pickup, "days") + 1;
      break;
    }
  }

  return (
    <AddressAnswer>
      {nextSweep && (
        <AddressAnswerBlock icon image={calendarIcon}>
          <p>
            Your next street sweep is <b>{nextSweep}</b>.
            {tMinus &&
              ` That is ${tMinus} day${tMinus > 1 ? "s" : ""} from now.`}
          </p>
        </AddressAnswerBlock>
      )}
    </AddressAnswer>
  );
};

const Parking = ({ permitData }: { permitData: ParkingPermit }) => {
  return (
    <AddressAnswer>
      <AddressAnswerBlock icon image={carIcon}>
        {permitData.PermitZone === "None" ? (
          <p>
            You do{" "}
            <b>
              <u>not</u>
            </b>{" "}
            need a residential parking permit.
          </p>
        ) : (
          <p>
            Your address is part of the <b>{permitData.PermitZone}</b> permit
            zone.
          </p>
        )}
      </AddressAnswerBlock>
    </AddressAnswer>
  );
};

function parseAnswer(
  answer: string,
  addressResponse: AddressResponseData
): string {
  return answer.replace(/%{2}(.*)%{2}/g, item => {
    item = item.replace(/%%/g, "").trim();
    let value: any = addressResponse;
    item.split(".").forEach(key => {
      value = value && value.hasOwnProperty(key) ? value[key] : null;
    });
    return value || "N/A";
  });
}

export default ({
  answer,
  lang,
  addressResponse,
  type
}: AddressAnswerProps): React.ReactElement => {
  if (isAddressResponseError(addressResponse)) {
    return (
      <AddressAnswer>
        <AnswerText>{ADDRESS_NOT_FOUND_TEXT(lang)}</AnswerText>
      </AddressAnswer>
    );
  }
  return (
    <div>
      {type === "PK" && (
        <PickupDay
          calendar={addressResponse.EventCalendar}
          wasteCollection={addressResponse.WasteCollection}
        />
      )}
      {type === "BP" && (
        <BulkPickup
          hauler={addressResponse.WasteCollection.Recycling.Hauler}
          phone={addressResponse.WasteCollection.Recycling.Phone}
        />
      )}
      {type === "CT" && (
        <Cart
          hauler={addressResponse.WasteCollection.Garbage.Hauler}
          phone={addressResponse.WasteCollection.Garbage.Phone}
        />
      )}
      {type === "PR" && <Parking permitData={addressResponse.ParkingPermit} />}
      {type === "SS" && (
        <StreetSweeping
          calendar={addressResponse.EventCalendar}
          sweepData={addressResponse.StreetSweeping}
        />
      )}
      {answer && (
        <AnswerText
          containsHTML={containsHTML(answer)}
          dangerouslySetInnerHTML={{
            __html: parseAnswer(answer, addressResponse)
          }}
        />
      )}
    </div>
  );
};
