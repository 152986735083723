/// <reference path="../styled.d.ts" />

import { createGlobalStyle } from "styled-components";
import { sanJoseTheme } from "../san-jose-theme";

const GlobalStyle = createGlobalStyle`
    body {
        background: #fff;
        font-family: ${sanJoseTheme.fonts.opensans};
        font-size: ${sanJoseTheme.fonts.size.regular};
        margin: 0;
        overflow-x: hidden;
        background-color: #fff;
        color: #3F3F3F;
    }

    *:focus {
        outline: 0;
        box-shadow: 0 0 0 2px var(--primary);
    }

    a {
        color: #00819C;
        text-decoration: none;
    }

    ul {
        list-style: circle;
        margin-left: 16px;
        padding: 0;
    }
`;

export default GlobalStyle;
