import styled, { css } from "styled-components";

const Text = styled.p<{ containsHTML?: boolean }>`
  font-family: ${props => props.theme.fonts.opensans};
  font-size: ${props => props.theme.fonts.size.regular};
  line-height: 1.4;
  word-break: break-word;
  margin: 16px 0;
  ${props =>
    !props.containsHTML &&
    css`
      white-space: pre-line;
    `}

  a {
    text-decoration: underline;
  }
`;

export default Text;
