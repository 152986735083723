import styled from "styled-components";

const StyledLabel = styled.label`
  position: absolute;
  font-family: ${props => props.theme.fonts.opensans}, sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  cursor: text;
  pointer-events: none;
  padding: 12px;
  z-index: 1;
  display: block;
  left: 1px;
  top: 0;
`;

export default StyledLabel;
