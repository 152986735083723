import * as React from "react";
import styled, { keyframes, css } from "styled-components";
import { Link } from "react-router-dom";
import Icon from "./icon";
const chevron = require("../icon--chevron.svg");

const LiItemReveal = keyframes`
  0% {
      opacity: 0;

      transform: translateY(3em);
  }

  100% {
      opacity: 1;

      transform: translateY(0em);
  }
`;

function createCSS() {
  let styles = "";
  for (let i = 1; i < 100; i++) {
    styles += `
      &:nth-of-type(${i}) {
        animation-delay: ${i * 100 + "ms"};
      }
    `;
  }
  return css`
    ${styles}
  `;
}

const StyledLineItem = styled.li<{
  noAnimation?: boolean;
  noBorder?: boolean;
  noChevron?: boolean;
  tight?: boolean;
}>`
  cursor: pointer;
  background-color: transparent;
  border: 0 none;
  position: relative;
  border-bottom: ${props => (props.noBorder ? 0 : 1)}px solid #e9e9e9;
  font-family: ${props => props.theme.fonts.opensans};
  font-size: 14px;
  line-height: 140%;
  position: relative;
  width: 100%;
  list-style: none;
  transform: translateY(3em);
  animation: ${LiItemReveal} ${props => (props.noAnimation ? 0 : 300)}ms
    forwards;
  opacity: 0;
  text-align: left;
  width: 100%;
  ${props => !props.noAnimation && createCSS};

  a {
    color: #003a49;
    display: block;
    padding: ${props => (props.tight ? 10 : 16)}px 32px
      ${props => (props.tight ? 10 : 16)}px 0;

    body.has-tabbed & {
      &:active {
        box-shadow: none;
        &:after {
          border: 2px solid ${props => props.theme.colors.primary};
        }
      }
    }
  }

  &:before {
    content: "";
    position: absolute;
    left: -50vw;
    right: -50vw;
    top: 0;
    bottom: 0;
    transition: ${props => props.theme.transition};
    z-index: -1;
  }

  &:hover,
  &:focus {
    &:before {
      background-color: #f0f0f0;
    }
  }

  &:after {
    position: absolute;
    background-image: url(${chevron});
    background-repeat: no-repeat;
    background-position: 99% center;
    background-size: 7px;
    border: 2px solid transparent;
    top: 0;
    right: -3px;
    bottom: 0;
    content: "";
    display: ${props => (props.noChevron ? "none" : "block")};
    width: 20px;
  }
`;

const FlexGap10 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  &:nth-last-child {
    flex: 1;
  }
`;

interface LineItemProps {
  dangerouslySetInnerHTML?: { __html: string };
  history: string[];
  href: string;
  onClick?: () => void;
  noAnimation?: boolean;
  noBorder?: boolean;
  noChevron?: boolean;
  setHistory: React.Dispatch<React.SetStateAction<string[]>>;
  tight?: boolean;
  icon?: string;
  setUseQuery?: React.Dispatch<React.SetStateAction<boolean>>;
}

const LineItem: React.FC<LineItemProps> = props => {
  const {
    dangerouslySetInnerHTML,
    history,
    href,
    onClick,
    setHistory,
    setUseQuery,
    ...rest
  } = props;
  return (
    <>
      <StyledLineItem {...rest}>
        <Link
          to={href}
          onClick={() => {
            onClick && onClick();
            setUseQuery && setUseQuery(false);
            setHistory(
              history.concat(window.location.pathname + window.location.search)
            );
          }}
        >
          <FlexGap10>
            {props.icon ? <Icon icon={props.icon} size={"20px"} /> : <></>}
            <div dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
          </FlexGap10>
        </Link>
      </StyledLineItem>
    </>
  );
};

export default LineItem;
