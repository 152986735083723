import styled from "styled-components";

const Prompt = styled.h1`
  color: #003a49;
  font-family: ${props => props.theme.fonts.opensans}, sans-serif;
  font-size: ${props => props.theme.fonts.size.h1};
  font-weight: ${props => props.theme.fonts.weight.regular};
`;

export default Prompt;
