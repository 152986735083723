import styled, { css } from "styled-components";

import AnswerDetails from "./answerDetails";

const Text = styled(AnswerDetails)<{ containsHTML?: boolean }>`
  border-top: 1px solid rgb(220, 222, 219);
  border-bottom: 1px solid rgb(220, 222, 219);
  padding-top: 24px;
  padding-bottom: 24px;
  margin-bottom: 24px;
`;

export default Text;
