import * as React from "react";
import { OPTION_LABEL_TEXT, OPTION_TEXT, Languages } from "../resources";
import ListLabel from "./listLabel";
import UnorderedList from "./unorderedList";
import LineItem from "./lineItems";
import Icon from "./icon";

const AnswerOptions = ({
  lang,
  history,
  setHistory
}: {
  lang: Languages;
  history: string[];
  setHistory: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  return (
    <>
      <UnorderedList>
        {OPTION_TEXT(lang).map((text, i) => {
          /**
           * Since there's only two answer options, this is a very hardcoded,
           * very not scalable implementation of linking to "help" [index 0]
           * (the fallback intent) and returning to the menu [index 1].
           */
          return (
            <LineItem
              history={history}
              key={i}
              href={i === 1 ? "/" : "/help"}
              icon={i === 1 ? "--icon-arrow-back" : "--icon-help"}
              noChevron={i === 1}
              dangerouslySetInnerHTML={{ __html: text }}
              setHistory={setHistory}
            />
          );
        })}
      </UnorderedList>
    </>
  );
};

export default AnswerOptions;
