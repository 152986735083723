/**
 * Given an array of utterances, pick the 'best' one from it
 * (the one with the longest sequence of <b>BOLD TEXT</b> in it,
 * as determined by the back-end search.)
 * @param utterances
 */
export default function bestUtterance(utterances: string[]): string {
  if (!utterances) return "";
  let longestMatch = 0;
  let index = 0;
  utterances.forEach((utterance, i) => {
    const boldText = utterance.match(/<b>([^<b>]*)<\/b>/g);
    if (!boldText) return;
    const length = boldText.join("").replace(/<(\\)b>/g, "").length;
    if (length > longestMatch) {
      index = i;
      longestMatch = length;
    }
  });
  return utterances[index];
}
