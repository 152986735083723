import * as React from "react";
import styled from "styled-components";
import LanguageButton from "./languageButton";
import { Languages } from "../resources";

const LanguageBackground = styled.div`
  display: flex;
  align-items: flex-end;
  position: fixed;
  padding-bottom: 12px;
  width: 100%;
  bottom: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 75%);

  &:before {
    content: "";
    height: 52px;
    position: absolute;
    top: -12px;
    bottom: 0;
    left: -50vw;
    right: -50vw;
    pointer-events: none;
    display: block;
    background: #ededed;
  }
`;

interface LanguageBtnBackgroundProps {
  lang: string;
  onChange: (lang: Languages) => void;
}

const LanguageButtonContainer: React.FC<LanguageBtnBackgroundProps> = props => {
  return (
    <LanguageBackground>
      {
        // @ts-ignore
        Object.values(Languages).map(lang => {
          if (lang === props.lang) return null;
          return (
            <LanguageButton
              type="button"
              onClick={() => props.onChange(lang)}
              key={lang}
            >
              {lang}
            </LanguageButton>
          );
        })
      }
    </LanguageBackground>
  );
};

export default LanguageButtonContainer;
