import styled from "styled-components";

const LangBtn = styled.button`
  color: #003a49;
  font-family: ${props => props.theme.fonts.opensans}, sans-serif;
  font-style: normal;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: -0.2px;
  text-align: center;
  background-color: #ededed;
  border: 1px solid #ededed;
  border-radius: 46px;
  padding: 4px 8px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition: ${props => props.theme.transition};
  margin-right: 1rem;

  &:hover,
  &:focus {
    border: 1px solid rgba(7, 65, 76, 0.5);
  }
`;

export default LangBtn;
