import * as React from "react";
import { throttle } from "lodash";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
const arrow = require("../icon--arrow.svg");

const BackBtn = styled.div`
  color: #003a49;
  background-color: #f7f7f7;
  font-family: ${props => props.theme.fonts.opensans}, sans-serif;
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
  border: 0;
  border-radius: 25px;
  cursor: pointer;
  position: fixed;
  padding: 8px 10px;
  top: 14px;
  left: 28px;
  z-index: 2;
  &:before {
    content: "";
    background-image: url(${arrow});
    background-repeat: no-repeat;
    display: inline-block;
    width: 11px;
    height: 10px;
    background-size: 100%;
    margin-right: 0.5rem;
    transition: ${props => props.theme.transition};
  }
  &:hover,
  &:focus {
    background-color: #edf8fa;
  }

  & > a {
    color: #003a49;
  }
`;

const BackBtnBackground = styled.div<{
  backgroundColor: string;
  shadow?: boolean;
}>`
  position: fixed;
  height: 56px;
  width: 100%;
  background-color: ${props => props.backgroundColor};
  top: 0;
  left: 0;
  z-index: 2;
  transition: background-color ease-in-out 200ms, box-shadow ease-in-out 200ms;

  ${props =>
    props.shadow &&
    css`
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    `}
`;

interface BackBtnProps {
  history: string[];
  onClick?: () => void;
  setHistory: React.Dispatch<React.SetStateAction<string[]>>;
  scrollShadow?: boolean;
}

const BackButton: React.FC<BackBtnProps> = ({
  children,
  history,
  onClick,
  setHistory,
  scrollShadow
}) => {
  const href = onClick
    ? null
    : history.length === 0
    ? "/"
    : history[history.length - 1];
  const [backgroundColor, setBackgroundColor] = React.useState<string>(
    "transparent"
  );
  if (scrollShadow !== false) {
    const onScroll = throttle(() => {
      setBackgroundColor(
        window.pageYOffset || window.scrollY > 40 ? "white" : "transparent"
      );
    }, 250);
    React.useEffect(() => {
      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
    });
  }
  const shadow = {
    shadow: backgroundColor === "white"
  };
  return (
    <>
      <BackBtnBackground backgroundColor={backgroundColor} {...shadow} />
      {onClick ? (
        <BackBtn
          onClick={e => {
            e.preventDefault();
            onClick();
          }}
        >
          {children}
        </BackBtn>
      ) : (
        <BackBtn>
          <Link onClick={() => setHistory(history.slice(0, -1))} to={href}>
            {children}
          </Link>
        </BackBtn>
      )}
    </>
  );
};

export default BackButton;
