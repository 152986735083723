import * as React from "react";
import styled from "styled-components";

const StyledBlock = styled.div`
  border-top: 1px solid #dcdedb;
  border-bottom: 1px solid #dcdedb;
  font-family: ${props => props.theme.fonts.opensans};
  font-size: ${props => props.theme.fonts.size.regular};
  padding: 18px 8px;
  display: flex;
  align-items: center;
  margin: 24px 0 32px 0;

  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
`;

const StyledImage = styled.img`
  width: auto;
  height: 52px;
  max-width: 100%;
  max-height: 100%;
  padding-right: 24px;
`;

const StyledIcon = styled.div<{ src: string }>`
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.action_background};
  height: 56px;
  width: 56px;
`;

export default ({
  icon = false,
  image,
  children
}: {
  icon?: boolean;
  image?: string;
  children: React.ReactNode;
}) => {
  return (
    <StyledBlock>
      {image && icon ? (
        <StyledIcon src={image} style={{ marginRight: 12 }} />
      ) : image ? (
        <StyledImage src={image} />
      ) : null}
      <div>{children}</div>
    </StyledBlock>
  );
};
