import { Languages } from "../resources";

export default function languageToCode(
  lang: Languages
): "en" | "es" | "vi" | "zh" {
  switch (lang) {
    case Languages.ES:
      return "es";
    case Languages.ZH:
      return "zh";
    case Languages.VI:
      return "vi";
    default:
      return "en";
  }
}
