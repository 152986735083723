import * as React from "react";
import styled, { keyframes } from "styled-components";
import { Languages } from "../resources";
import {
  getFeedbackPrompt,
  getFeedbackThanks,
  getFeedbackMessageThanks,
  getFeedbackInputPlaceholder
} from "../api/getResources";

import postFeedback from "../api/postFeedback";
import languageToCode from "../utils/languageToCode";
import QuestionForm from "./questionForm";

const thumbsUp = require("../../images/thumbs-up-outline.svg");
const thumbsDown = require("../../images/thumbs-down-outline.svg");

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
`;

const FeedbackPrompt = styled.div<{ center: boolean }>`
  animation: 0.5s ${slideIn} forwards;
  align-items: center;
  background: #00657f;
  bottom: 0;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.2);
  min-height: 40px;
  left: 0;
  padding: 16px 32px;
  position: fixed;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 636px;
`;

const ThumbButton = styled.button`
  appearance: none;
  background: transparent;
  border: 0 none;
  cursor: pointer;
  height: 22px;
  width: 22px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: 20px;
  transition: 0.2s transform;
  &:first-child {
    margin-left: 0;
  }
  &:hover {
    transform: scale(1.1);
  }
`;

export default ({
  analyticsId,
  csrf,
  lang
}: {
  analyticsId: number;
  csrf: string;
  lang: Languages;
}) => {
  if (!csrf) return null;

  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const [submittedForm, setSubmittedForm] = React.useState<boolean>(false);
  const [feedback, setFeedback] = React.useState<string>("");
  const prompt = getFeedbackPrompt(languageToCode(lang));
  const thanks = getFeedbackThanks(languageToCode(lang));
  const messageThanks = getFeedbackMessageThanks(languageToCode(lang));
  const inputPlaceholder = getFeedbackInputPlaceholder(languageToCode(lang));

  return (
    <FeedbackPrompt center={submitted}>
      <Inner>
        {!submitted ? (
          <div
            style={{
              display: "flex",
              gap: "20px",
              justifyContent: "space-between",
              flex: 1
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: prompt }} />
            <div>
              <ThumbButton
                style={{ backgroundImage: `url(${thumbsUp})` }}
                onClick={() =>
                  postFeedback({
                    analyticsId,
                    csrf,
                    helpful: true
                  }).then(() => setSubmitted(true))
                }
              />
              <ThumbButton
                style={{ backgroundImage: `url(${thumbsDown})` }}
                onClick={() =>
                  postFeedback({
                    analyticsId,
                    csrf,
                    helpful: false
                  }).then(() => setSubmitted(true))
                }
              />
            </div>
          </div>
        ) : !submittedForm ? (
          <div style={{ width: "100%" }}>
            <div
              dangerouslySetInnerHTML={{ __html: thanks }}
              style={{ marginBottom: 20 }}
            />
            <QuestionForm
              placeholder={inputPlaceholder}
              lang={lang}
              query={feedback}
              onChange={e => setFeedback(e.currentTarget.value)}
              onSubmit={e => {
                e.preventDefault();
                postFeedback({
                  analyticsId,
                  csrf,
                  message: feedback
                }).then(res => setSubmittedForm(true));
              }}
            />
          </div>
        ) : submittedForm ? (
          <div dangerouslySetInnerHTML={{ __html: messageThanks }} />
        ) : null}
      </Inner>
    </FeedbackPrompt>
  );
};
