import * as React from "react";
import * as store from "store";
import * as queryString from "query-string";
import AnswerOptions from "../components/answerOptions";
import { useLocation } from "react-router-dom";
import {
  Languages,
  ADDRESS_INPUT_LABEL,
  ADDRESS_TEXT,
  FOUND_ANSWER_TEXT
} from "../resources";
import addressLookup, {
  AddressResponseError,
  AddressResponseData
} from "../api/addressLookup";
import QuestionForm from "../components/questionForm";
import AddressInfo from "../components/addressInfo";
import addressAutocomplete from "../api/addressAutocomplete";
import LineItem from "../components/lineItems";
import { IntentDetailResponse } from "../api/intentDetailResponse";
import Prompt from "../components/prompt";
import Loader from "../components/loader";
import AddressAnswer from "../components/addressAnswer";
import FeedbackPrompt from "../components/feedbackPrompt";

// given an address, add it to the user's local storage (address history)
// and send it to the API
const getAddressData = (
  address: string
): Promise<AddressResponseData | AddressResponseError> => {
  // remember addresses the user has tried
  let addressHistory: string[] = store.get("sj-assistant-address-history");
  if (!addressHistory) addressHistory = [];
  if (addressHistory.includes(address)) {
    addressHistory.splice(addressHistory.indexOf(address), 1);
  }
  addressHistory.unshift(address);

  return addressLookup(address).then(addressResponse => {
    if (addressHistory.length > 5) addressHistory.pop();
    store.set("sj-assistant-address-history", addressHistory);
    return addressResponse;
  });
};

export default ({
  answer,
  csrf,
  history,
  lang,
  setHistory,
  setRedirect
}: {
  answer: IntentDetailResponse;
  csrf: string;
  history: string[];
  lang: Languages;
  setHistory: React.Dispatch<React.SetStateAction<string[]>>;
  setRedirect: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [addressResponse, setAddressResponse] = React.useState<
    AddressResponseData | AddressResponseError
  >(null);
  const [address, setAddress] = React.useState<string>("");
  const [addressSuggestions, setAddressSuggestions] = React.useState<string[]>(
    []
  );

  const { search } = useLocation();
  let searchAddress = queryString.parse(search).address;

  React.useEffect(() => {
    if (!searchAddress) return setAddressResponse(null);
    if (typeof searchAddress !== "string") searchAddress = searchAddress[0];
    setAddress(searchAddress);
    getAddressData(searchAddress).then(setAddressResponse);
  }, [searchAddress]);

  // if coming to this view with URL parameter in tow
  if (searchAddress && addressResponse === null) {
    return (
      <>
        <Prompt>Just one minute...</Prompt>
        <Loader />
      </>
    );
  }

  // before entering and searching for an address
  if (addressResponse === null) {
    return (
      <>
        <Prompt dangerouslySetInnerHTML={{ __html: ADDRESS_TEXT(lang) }} />
        <QuestionForm
          lang={lang}
          query={address}
          onChange={e => {
            setAddress(e.currentTarget.value);
            addressAutocomplete(e.currentTarget.value).then(
              setAddressSuggestions
            );
          }}
          onSubmit={e => {
            e.preventDefault();
            setRedirect(location.pathname + "?address=" + address);
          }}
          placeholder={ADDRESS_INPUT_LABEL(lang)}
        />
        <>
          {addressSuggestions.map((s, i) => {
            return (
              <LineItem
                key={"address-suggestion-" + s + "-" + i}
                history={history}
                href={"?address=" + s}
                noAnimation
                noBorder
                tight
                dangerouslySetInnerHTML={{ __html: s }}
                setHistory={setHistory}
              />
            );
          })}
        </>
        <AddressInfo history={history} setHistory={setHistory} lang={lang}>
          {answer.context_statement}
        </AddressInfo>
      </>
    );
  }

  // after receiving a response from the API
  return (
    <>
      <Prompt
        dangerouslySetInnerHTML={{
          __html: FOUND_ANSWER_TEXT(lang, address)
        }}
      />
      <AddressAnswer
        addressResponse={addressResponse}
        answer={answer.answer_details}
        history={history}
        lang={lang}
        type={answer.address_intent_type}
        setHistory={setHistory}
      />
      <AnswerOptions history={history} lang={lang} setHistory={setHistory} />
      <FeedbackPrompt
        analyticsId={answer.analytics_id}
        csrf={csrf}
        lang={lang}
      />
    </>
  );
};
