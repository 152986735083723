import * as React from "react";

const arrowBack = require("../../images/icons/arrow-back.svg");
const bone = require("../../images/icons/bone.svg");
const card = require("../../images/icons/card.svg");
const feedback = require("../../images/icons/feedback.svg");
const headset = require("../../images/icons/headset.svg");
const help = require("../../images/icons/help.svg");
const info = require("../../images/icons/info.svg");
const like = require("../../images/icons/like.svg");
const question = require("../../images/icons/question.svg");
const recycling = require("../../images/icons/recycling.svg");
const truck = require("../../images/icons/truck.svg");
const virus = require("../../images/icons/virus.svg");

const iconMap = new Map([
  ["--icon-arrow-back", arrowBack],
  ["--icon-bone", bone],
  ["--icon-card", card],
  ["--icon-feedback", feedback],
  ["--icon-headset", headset],
  ["--icon-help", help],
  ["--icon-info", info],
  ["--icon-like", like],
  ["--icon-question", question],
  ["--icon-recycling", recycling],
  ["--icon-truck", truck],
  ["--icon-virus", virus]
]);

interface IconProps {
  icon?: string;
  size?: string;
}

const Icon: React.FC<IconProps> = props => {
  const { icon, size, ...rest } = props;
  const iconFile = iconMap.get(icon) || question;
  const iconSize = size || "20px";
  return (
    <div
      style={{
        width: size,
        height: size,
        backgroundImage: `url(${iconFile})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center"
      }}
    />
  );
};

export default Icon;
