import * as React from "react";
import * as store from "store";
import LineItem from "./lineItems";
import ListLabel from "./listLabel";
import { SEARCH_HISTORY_TEXT, Languages } from "../resources";

export default ({
  lang,
  history,
  setHistory
}: {
  lang: Languages;
  history: string[];
  setHistory: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  return (
    <div>
      <ListLabel style={{ paddingTop: "0.5em" }}>
        {SEARCH_HISTORY_TEXT(lang)}
      </ListLabel>
      {store
        .get("sj-assistant-address-history")
        .map((address: string, i: number) => {
          return (
            <LineItem
              history={history}
              key={i}
              dangerouslySetInnerHTML={{ __html: address }}
              href={"?address=" + address}
              noBorder
              noChevron
              tight
              setHistory={setHistory}
            />
          );
        })}
    </div>
  );
};
