import * as React from "react";
import { throttle } from "lodash";
import { Languages, BACK_TEXT } from "../resources";
import QuestionForm from "../components/questionForm";
import Prompt from "../components/prompt";
import Suggestions from "../components/suggestions";
import BackButton from "../components/backButton";
import languageToCode from "../utils/languageToCode";
import LanguageButtonContainer from "../components/languageButtonContainer";
import { getSuggestions } from "../api/getResources";
import { Intent } from "../types/Intent";
import Loader from "../components/loader";
import { IntentDetailResponse } from "../api/intentDetailResponse";
import ContentContainer from "../components/contentContainer";
import MainContainer from "../components/mainContainer";
import TopContainer from "../components/topContainer";
import { useHistory } from "react-router-dom";
import * as H from "history";

const displayResults = throttle(
  (
    res: Intent[],
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setResults: React.Dispatch<React.SetStateAction<Intent[]>>
  ): void => {
    setLoading(false);
    setResults(res);
  },
  750
);

const search = throttle(
  (
    text: string,
    lang: Languages,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setResults: React.Dispatch<React.SetStateAction<Intent[]>>
  ): void => {
    setLoading(true);
    fetch(
      `/api/intents/search/?text=${text}&count=8&lang=${languageToCode(lang)}`
    )
      .then(res => res.json())
      .then(res => {
        displayResults(res, setLoading, setResults);
      });
  },
  750
);

const getAnswerByText = ({
  lang,
  results,
  setAnswer,
  setLoading,
  browserHistory,
  text
}: {
  lang: Languages;
  results: Intent[];
  setAnswer: React.Dispatch<React.SetStateAction<IntentDetailResponse>>;
  browserHistory: H.History;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  text: string;
}) => {
  const code = languageToCode(lang);
  setLoading(true);

  // if fuzzy search matches the top result pretty well,
  // assume that we want it
  if (results.length > 0 && results[0].confidence > 0.5) {
    browserHistory.push(`/intent/${results[0].name}`);
  }

  // if we haven't matched a top result well enough, then
  // make the request to Wit
  fetch(`/api/intents/search/?text=${text}&count=1&lang=${code}`)
    .then(res => res.json())
    .then(res => {
      setLoading(false);
      if (res.name === "Help_Web") {
        browserHistory.push("/help");
      } else {
        setAnswer(res);
        browserHistory.push(`/intent/${res.name}`);
      }
    });
};

export default ({
  history,
  initialFocus,
  lang,
  results,
  query,
  setQuery,
  setAnswer,
  setHistory,
  setLang,
  setUseQuery
}: {
  history: string[];
  initialFocus: boolean;
  lang: Languages;
  onSubmit?: (event: React.SyntheticEvent<HTMLElement>) => any;
  query: string;
  results: Intent[];
  setAnswer: React.Dispatch<React.SetStateAction<IntentDetailResponse>>;
  setHistory: React.Dispatch<React.SetStateAction<string[]>>;
  setLang: (lang: Languages) => void;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  setRedirect: React.Dispatch<React.SetStateAction<string>>;
  setResults: React.Dispatch<React.SetStateAction<Intent[]>>;
  setUseQuery: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [hasFocused, setHasFocused] = React.useState<boolean>(initialFocus);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [scrollShadow, setScrollShadow] = React.useState<boolean>(false);
  const suggestions = getSuggestions(languageToCode(lang));
  const welcomeMessage = JSON.parse(
    document.getElementById("welcome-message").textContent
  );
  const code = languageToCode(lang);

  const browserHistory = useHistory();

  // always clear the answer in this view so that a flash of it
  // isn't seen if going from an answer to here then to a different answer
  React.useEffect(() => setAnswer(null), []);

  const getAnswer = (text: string): void => {
    if (!text || text.trim() === "") return;
    setUseQuery(true);
    getAnswerByText({
      text,
      lang,
      results,
      setAnswer,
      browserHistory,
      setLoading
    });
  };

  const onScroll = throttle(() => {
    setScrollShadow(Boolean(window.pageYOffset || window.scrollY > 40));
  }, 250);
  React.useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  });

  return (
    <MainContainer>
      <TopContainer shadow={scrollShadow}>
        {hasFocused && (
          <BackButton
            history={history}
            setHistory={setHistory}
            scrollShadow={false}
            onClick={() => {
              setHasFocused(false);
              setQuery("");
            }}
          >
            {BACK_TEXT(lang)}
          </BackButton>
        )}
        <Prompt>{welcomeMessage[code]}</Prompt>
        <QuestionForm
          lang={lang}
          onChange={e => {
            const { value } = e.currentTarget;
            setQuery(value);
          }}
          onFocus={() => setHasFocused(true)}
          onKeyDown={e => {
            // treat enter/return as form submit, not new line (unless shift is pressed)
            if (e.keyCode === 13 && !e.shiftKey) {
              e.preventDefault();
              getAnswer(query);
            }
          }}
          onSubmit={e => {
            e.preventDefault();
            e.stopPropagation();
            getAnswer(query);
          }}
          query={query}
        />
      </TopContainer>
      <ContentContainer>
        <>
          {loading && <Loader />}
          <Suggestions
            history={history}
            setHistory={setHistory}
            suggestions={suggestions}
            lang={lang}
            setUseQuery={setUseQuery}
          />
          <LanguageButtonContainer onChange={setLang} lang={lang} />
        </>
      </ContentContainer>
    </MainContainer>
  );
};
