export interface AddressEvent {
  Month: string;
  Day: number;
  Year: number;
  Event: string;
}

interface HaulerInfo {
  Hauler: string;
  Phone: string;
}

export interface WasteCollection {
  Details: string;
  PickupDay: string;
  Garbage: HaulerInfo;
  Recycling: HaulerInfo;
  Type: string;
  YardTrimmings: HaulerInfo;
}

export interface StreetSweeping {
  Street: string;
  SweepDay: string;
  SweepTime: string;
  SweepType: string;
  ParkingRestrictions: string;
  Phone: string;
  Details: string;
  SweepDayCode: string;
  PTCOZone: string;
}

export interface ParkingPermit {
  Street: string;
  PermitZone: string;
  Expiration: string;
  PermitLimit: string;
  HangerLimit: number;
  Application: string;
}

export type AddressIntentType = "CT" | "PK" | "BP" | "SS" | "PR";
export interface AddressResponseData {
  EventCalendar: AddressEvent[];
  WasteCollection: WasteCollection;
  StreetSweeping: StreetSweeping;
  ParkingPermit: ParkingPermit;
}

export interface AddressResponseError {
  result: string;
}

export function isAddressResponseError(o: any): o is AddressResponseError {
  return o && o.result;
}

export default async function addressLookup(
  address: string
): Promise<AddressResponseData | AddressResponseError> {
  return fetch("/api/address/?address=" + address).then(r => r.json());
}
