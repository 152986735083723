export default async function postFeedback({
  analyticsId,
  csrf,
  helpful,
  message
}: {
  analyticsId: number;
  csrf: string;
  helpful?: boolean;
  message?: string;
}): Promise<any> {
  const data = new FormData();
  data.append("analytics_id", analyticsId.toString());
  if (helpful !== undefined) data.append("helpful", helpful ? "1" : "0");
  if (message) data.append("message", message);
  data.append("csrfmiddlewaretoken", csrf);
  return fetch("/api/feedback/", {
    method: "POST",
    body: data
  }).then(res => res.json());
}
