import styled from "styled-components";

const SubmitBtn = styled.button`
  border: 0;
  color: #003a49;
  cursor: pointer;
  font-family: ${props => props.theme.fonts.opensans}, sans-serif;
  font-size: ${props => props.theme.fonts.size.button};
  font-weight: ${props => props.theme.fonts.weight.medium};
  letter-spacing: -0.2px;

  position: relative;
  left: 0;
  text-align: center;

  transition: all 250s easy-in-out;
  opacity: 0;
  height: 0;
  padding: 0;
  margin-top: 0;

  background: #edf8fa;
  border-radius: 25px;

  &:hover,
  &:focus {
    color: #ffffff;
    background: #07414c;
    border-radius: 25px;
  }

  &:focus {
    margin-top: 10px;
    padding: 8px 16px;
    height: 34px;
    opacity: 1;
  }
`;

export default SubmitBtn;
