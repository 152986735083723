import styled from "styled-components";
import * as React from "react";

const ContentContainerFlex = styled.div`
  display: flex;
  flex: 2;
  position: relative;
`;

const Background = styled.div`
  position: absolute;
  left: -50vw;
  right: -50vw;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: #f7f7f7;
`;

class ContentContainer extends React.Component {
  render() {
    return (
      <ContentContainerFlex>
        <Background />
        <div style={{ width: "100%" }}>{this.props.children}</div>
      </ContentContainerFlex>
    );
  }
}

export default ContentContainer;
