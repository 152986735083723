import * as React from "react";
import styled, { ThemeProvider } from "styled-components";
import { sanJoseTheme } from "../san-jose-theme";

const Wrap = styled.div`
  max-width: 700px;
  padding: 48px 32px;
  margin: 0 auto;
`;

interface WrapperProps {
  children: object;
}

const Wrapper: React.FC<WrapperProps> = props => {
  return (
    <ThemeProvider theme={sanJoseTheme}>
      <Wrap>{props.children}</Wrap>
    </ThemeProvider>
  );
};

export default Wrapper;
