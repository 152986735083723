import * as React from "react";
import { getHelp } from "../api/getResources";
import { Languages, HELP, FOUND_ANSWER_TEXT } from "../resources";
import languageToCode from "../utils/languageToCode";
import Prompt from "../components/prompt";
import AnswerDetails from "../components/answerDetails";
import AnswerOptions from "../components/answerOptions";

export default ({
  lang,
  history,
  query,
  useQuery,
  setHistory
}: {
  lang: Languages;
  history: string[];
  query: string;
  useQuery: boolean;
  setHistory: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const help = getHelp(languageToCode(lang));
  return (
    <>
      <Prompt
        dangerouslySetInnerHTML={{
          __html: FOUND_ANSWER_TEXT(
            lang,
            query && useQuery ? `"${query}"` : HELP(lang)
          )
        }}
      />
      <AnswerDetails dangerouslySetInnerHTML={{ __html: help }} />
      <AnswerOptions history={history} lang={lang} setHistory={setHistory} />
    </>
  );
};
