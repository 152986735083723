import styled, { css } from "styled-components";

const TopContainer = styled.div<{
  shadow?: boolean;
}>`
  width: 100%;
  position: sticky;
  top: 48px;
  background: #ffffff;
  z-index: 10;
  &:before {
    content: "";
    position: absolute;
    left: -50vw;
    right: -50vw;
    top: -50vh;
    bottom: 0;
    z-index: -1;
    background: #ffffff;

    ${props =>
      props.shadow &&
      css`
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      `}
  }
`;

export default TopContainer;
