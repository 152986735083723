export interface Suggestion {
  name: string;
  utterances: string;
  icon?: string;
  children?: Suggestion[];
}

export function getSuggestions(lang: "en" | "es" | "vi" | "zh"): Suggestion[] {
  let suggestions = JSON.parse(
    document.getElementById("suggestions").textContent
  )[lang];
  let topQuestions = JSON.parse(
    document.getElementById("top-questions").textContent
  )[lang];
  return [...suggestions, ...topQuestions];
}

export function getHelp(lang: "en" | "es" | "vi" | "zh"): string {
  return JSON.parse(document.getElementById("help-web").textContent)[lang];
}

export function getFeedbackPrompt(lang: "en" | "es" | "vi" | "zh"): string {
  return JSON.parse(document.getElementById("feedback-prompt").textContent)[
    lang
  ];
}

export function getFeedbackThanks(lang: "en" | "es" | "vi" | "zh"): string {
  return JSON.parse(document.getElementById("feedback-thanks").textContent)[
    lang
  ];
}

export function getFeedbackMessageThanks(
  lang: "en" | "es" | "vi" | "zh"
): string {
  return JSON.parse(
    document.getElementById("feedback-message-thanks").textContent
  )[lang];
}

export function getFeedbackInputPlaceholder(
  lang: "en" | "es" | "vi" | "zh"
): string {
  return JSON.parse(
    document.getElementById("feedback-input-placeholder").textContent
  )[lang];
}
