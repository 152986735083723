import * as React from "react";
import * as store from "store";
import styled, { keyframes } from "styled-components";
import AddressHistory from "./addressHistory";
import {
  Languages,
  ADDRESS_WHY_TEXT,
  ADDRESS_NO_TRACK_TEXT
} from "../resources";
const iconUrl = require("../../images/icon--address.svg");

const fadeInKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translateY(3em);
  }

  100% {
    opacity: 1;
    transform: translateY(0em);
  }
`;

const InfoBox = styled.div`
  animation: ${fadeInKeyframes} 300ms forwards;
  animation-delay: 200ms;
  border: 1px solid #dcdedb;
  border-radius: 16px;
  color: ${props => props.theme.colors.gray_dark};
  opacity: 0;
  padding: 24px;
  text-align: center;
  font-family: ${props => props.theme.fonts.opensans}, sans-serif;
  font-size: ${props => props.theme.fonts.size.regular};
  margin-top: 48px;
  position: relative;

  h3 {
    font-size: ${props => props.theme.fonts.size.regular};
    margin-bottom: 12px;
  }

  p {
    font-size: ${props => props.theme.fonts.size.button};
    line-height: 1.5;
    margin-bottom: 0;
  }
`;

const Icon = styled.div`
  background-image: url(${iconUrl});
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.action_background};
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 56px;
  width: 56px;
`;

const NoTrack = styled.p`
  animation: ${fadeInKeyframes} 300ms forwards;
  animation-delay: 200ms;
  color: #828282;
  font-family: ${props => props.theme.fonts.opensans}, sans-serif;
  font-size: ${props => props.theme.fonts.size.button};
  font-style: italic;
  opacity: 0;
  text-align: center;
  margin: 24px 0;
`;

export default ({
  history,
  lang,
  children,
  setHistory
}: {
  lang: Languages;
  children: React.ReactNode;
  history: string[];
  setHistory: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const addressHistory = store.get("sj-assistant-address-history", []);
  return (
    <>
      {addressHistory.length > 0 && (
        <AddressHistory history={history} lang={lang} setHistory={setHistory} />
      )}
      {children && (
        <InfoBox>
          <Icon />
          <h3>{ADDRESS_WHY_TEXT(lang)}</h3>
          <p>{children}</p>
        </InfoBox>
      )}
      <NoTrack>{ADDRESS_NO_TRACK_TEXT(lang)}</NoTrack>
    </>
  );
};
