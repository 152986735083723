declare global {
  interface Window {
    REFERRER?: string;
  }
}

import { IntentDetailResponse } from "./intentDetailResponse";

export default async function getAnswerByName(
  name: string,
  code: "en" | "es" | "vi" | "zh",
  query: string = "",
  useQuery: boolean = true,
  isSuggestion: boolean = false
): Promise<IntentDetailResponse> {
  let url = `/api/intents/-1?name=${name}&lang=${code}&is_suggestion=${isSuggestion}`;
  url += (useQuery ? "&query=" : "&partial_query=") + query;

  /**
   * Get the referrer from the embedding iframe (if one exists)
   * and pass it to the API for analytics tracking.
   */
  if (typeof window.REFERRER === "string") {
    url += "&referrer=" + window.REFERRER;
  }

  return fetch(url).then(res => {
    return res.json();
  });
}
