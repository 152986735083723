import { DefaultTheme } from "styled-components";

/**
 * TODO: would love to be able to pull some theme values
 * from a global stylesheet or global variables that are set elsewhere,
 * to be able to individually brand separate instances of the Assistant
 */
const sanJoseTheme: DefaultTheme = {
  borderRadius: {
    small: "2px",
    large: "13px"
  },

  colors: {
    action: "var(--action)",
    primary: "var(--primary)",
    gray_dark: "var(--gray_dark)",
    gray_light: "var(--gray_light)",
    action_background: "var(--action_background)",
    white: "var(--white)"
  },

  fonts: {
    opensans: "Open Sans",
    teko: "Teko",
    weight: {
      regular: "400",
      medium: "600",
      heavy: "700"
    },
    size: {
      h1: "24px",
      h2: "18px",
      regular: "15px",
      button: "13px"
    }
  },

  transition: "all 300ms ease-in-out"
};

export { sanJoseTheme };
