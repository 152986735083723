import styled from "styled-components";

const Label = styled.div`
  color: #07414c;
  font-family: ${props => props.theme.fonts.opensans}, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  padding: 20px 0 12px 0;
  position: relative;
`;

export default Label;
