import styled, { css } from "styled-components";

const Text = styled.div<{ containsHTML?: boolean }>`
  font-family: ${props => props.theme.fonts.opensans};
  font-weight: 400;
  font-size: 14px;
  line-height: 19.07px;
  color: #003a49;
  word-break: break-word;
  margin: 16px 0;
  ${props =>
    !props.containsHTML &&
    css`
      white-space: pre-line;
    `}

  a {
    font-weight: 700;
  }

  ul,
  ol {
    margin-left: 40px;
    margin-bottom: 0;
  }

  ul {
    list-style-type: disc;
  }

  h4 {
    margin: 4px 0 6px;
  }
`;

export default Text;
