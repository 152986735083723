import * as React from "react";
import { Languages, SUGGESTION_LABEL_TEXT } from "../resources";
import ListLabel from "./listLabel";
import LineItem from "./lineItems";
import UnorderedList from "./unorderedList";
import { Suggestion } from "../api/getResources";

export default ({
  history,
  lang,
  setHistory,
  suggestions,
  setUseQuery
}: {
  history: string[];
  lang: Languages;
  setHistory: React.Dispatch<React.SetStateAction<string[]>>;
  suggestions: Suggestion[];
  setUseQuery: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <>
      <ListLabel>{SUGGESTION_LABEL_TEXT(lang)}</ListLabel>
      <UnorderedList>
        {suggestions.map((s, i) => {
          let title = s.utterances;
          return (
            <LineItem
              history={history}
              key={`suggestion-${lang}-${s.name}`}
              href={
                s.children
                  ? `/suggestions/${i}`
                  : `/intent/${s.name}?suggestion=true`
              }
              icon={s.icon || "--icon-question"}
              dangerouslySetInnerHTML={{
                __html: title
              }}
              setHistory={setHistory}
              setUseQuery={setUseQuery}
            />
          );
        })}
      </UnorderedList>
    </>
  );
};
