import * as React from "react";
import styled, { css } from "styled-components";
import QuestionLabel from "./questionLabel";
import SubmitButton from "./submitButton";

const focus = css`
  height: 145px;

  & ~ ${SubmitButton} {
    transition: all 250s easy-in-out;
    margin-top: 10px;
    padding: 8px 16px;
    height: 34px;
    transition-delay: 350ms;
    transition-timing-function: easy-out;
    transition-property: opacity;
    opacity: 1;
  }
`;

const StyledTextArea = styled.textarea<{ focus: boolean }>`
  resize: none;
  font-family: ${props => props.theme.fonts.opensans};
  border: 1px solid #dcdedb;
  border-radius: ${props => props.theme.borderRadius.small};
  box-sizing: border-box;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.05);
  padding: 12px;
  width: 100%;
  height: 43px;
  display: block;
  z-index: 2;
  transition: ${props => props.theme.transition};

  font-size: 14px;

  background: #f3f8fa;
  border: 1.5px solid rgba(0, 58, 73, 0.3);
  border-radius: 6px;

  overflow: hidden;

  &:-internal-autofill-selected,
  &:-webkit-autofill:focus &:-webkit-autofill {
    background: ${props => props.theme.colors.gray_light} !important;
  }

  &:hover {
    border: 1px solid #9f9f9f;
  }

  &:focus {
    border: 1.5px solid rgba(0, 58, 73, 0.6);
    box-shadow: 0 0 0 0 transparent;
    ${focus}
  }

  ${props => props.focus && focus}

  &:not(:empty) {
    ${focus}

    & + ${QuestionLabel} {
      display: none;
    }

    & ~ ${SubmitButton} {
      margin-top: 10px;
      padding: 8px 16px;
      height: 34px;
      opacity: 1;
    }
  }
`;

interface StyledTextAreaProps {
  focus: boolean;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  setFocus: React.Dispatch<React.SetStateAction<boolean>>;
  value: string;
}

const QuestionTextArea: React.FC<StyledTextAreaProps> = props => {
  // const textareaRef = React.useRef();
  const { focus, onFocus, setFocus, ...rest } = props;
  return (
    <StyledTextArea
      onBlur={() => setFocus(false)}
      onFocus={e => {
        onFocus && onFocus(e);
        setFocus(true);
      }}
      focus={focus}
      // ref={textareaRef}
      id="ask-question"
      minLength={2}
      required
      {...rest}
    />
  );
};

export default QuestionTextArea;
