import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import Wrapper from "./components/wrapper";
import GlobalStyle from "./components/globalStyle";
import BackButton from "./components/backButton";
import { BACK_TEXT, Languages } from "./resources";
import IndexView from "./views/IndexView";
import AnswerView from "./views/AnswerView";
import HelpView from "./views/HelpView";
import SuggestionsView from "./views/SuggestionsView";
import { IntentDetailResponse } from "./api/intentDetailResponse";
import { Intent } from "./types/Intent";
import languageToCode from "./utils/languageToCode";

const App = () => {
  const [answer, setAnswer] = React.useState<IntentDetailResponse>(null);
  const [history, setHistory] = React.useState<string[]>([]);
  const [lang, setLang] = React.useState<Languages>(Languages.EN);
  const [query, setQuery] = React.useState<string>("");
  const [useQuery, setUseQuery] = React.useState<boolean>(false);
  const [redirect, setRedirect] = React.useState<string>("");
  const [results, setResults] = React.useState<Intent[]>([]);

  const viewProps = {
    lang,
    history,
    setHistory
  };

  // update language state value, as well as update
  // the <html> `lang` attribute
  const wrappedSetLang = (lang: Languages) => {
    setLang(lang);
    document.documentElement.lang = languageToCode(lang);
  };

  return (
    <Wrapper>
      {redirect && <Redirect to={redirect} />}
      <GlobalStyle />
      <Route path="/:path">
        <BackButton history={history} setHistory={setHistory}>
          {BACK_TEXT(lang)}
        </BackButton>
      </Route>
      <Route path="/" exact>
        <IndexView
          initialFocus={false}
          query={query}
          results={results}
          setAnswer={setAnswer}
          setLang={wrappedSetLang}
          setQuery={setQuery}
          setRedirect={setRedirect}
          setResults={setResults}
          setUseQuery={setUseQuery}
          {...viewProps}
        />
      </Route>
      <Route path="/suggestions/:index" exact>
        <SuggestionsView setAnswer={setAnswer} {...viewProps} />
      </Route>
      <Route path="/intent/:intent" exact>
        <AnswerView
          answer={answer}
          query={query}
          setAnswer={setAnswer}
          setLang={wrappedSetLang}
          setRedirect={setRedirect}
          useQuery={useQuery}
          {...viewProps}
        />
      </Route>
      <Route path="/help" exact>
        <HelpView query={query} useQuery={useQuery} {...viewProps} />
      </Route>
    </Wrapper>
  );
};

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("app")
);
