import { Languages } from "../resources";

export default function codeToLanguage(
  lang: "en" | "es" | "vi" | "zh"
): Languages {
  switch (lang) {
    case "es":
      return Languages.ES;
    case "zh":
      return Languages.ZH;
    case "vi":
      return Languages.VI;
    default:
      return Languages.EN;
  }
}
