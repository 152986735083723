import styled from "styled-components";
import * as React from "react";

const StickyContainer = styled.div`
  position: sticky;
  background: #ffffff;
  bottom: 0;
`;

export default StickyContainer;
